

footerAccord = function () {
    if (detectmob()) {
        $(".footerLinkList h5").click(function () {
            $(this).parent().find("ul").toggle();
        });
    }
};

footerAccord();

kvkkClose = function () {
    $(".kvkkbox").hide();
}
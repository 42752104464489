startModalEvent = function (e) {


    var isBlockOnMobile = false;

    if ($(e.currentTarget)[0].hasAttribute('data-disable-mobile') && detectmob()) {
        isBlockOnMobile = true;
    }


    if ($(e.currentTarget).hasClass('modal-trigger') && !isBlockOnMobile) {
        var $elem = $(e.currentTarget);
        var $modal = $('.modal');

        var trigger = function () {
            var groupName = $elem.data('modal-group') || "";
            var href = $elem.data('href') || "";
            var single = $elem.data('single') || false;
            var modalSize = $elem.data('modal-size') || "";
            var addModalFunctionName = $elem.data('add-function') || false;
            var secondFunction = $elem.data('second-function') || false;
            addModal(groupName, href, single, modalSize, addModalFunctionName, secondFunction);
        };

        if ($modal.hasClass('in')) {
            $modal.on('hidden.bs.modal', function () {
                $modal.unbind('hidden.bs.modal');
                trigger();
            });



        } else {
            trigger();
        }
    }

};


module.exports = function () {

    /*init*/
    newModalMethods = function () {
        //formValidator();formValidator
    };

    newModalWindow = function (groupName, modalSize) {
        var modalWindow =
            '<div data-attr-group="' + groupName + '" class="modal fade modal-custom" tabindex="-1" role="dialog">' +
            '<div class="vertical-alignment-helper">' +
            '<div class="vertical-alignment-center">' +
            '<div class="modal-dialog ' + modalSize + '" role="document">' +
            '<div class="modal-content custom-modal">' +
            '<div class="modal-header"> ' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
            '<span aria-hidden="true"><i class="font-kapat"></i> </span>' +
            '</button> ' +
            '</div>' +
            '<div class="modal-external modal-body">' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';

        $('body').append($.parseHTML(modalWindow));


    };

    addModal = function (groupName, href, single, modalSize, addModalFunctionName, secondFunction) {

        if (groupName !== "") {
            if ($('.modal-custom[data-attr-group="' + groupName + '"]').length === 0) {
                newModalWindow(groupName, modalSize, addModalFunctionName);
            }
        } else {
            newModalWindow(groupName, modalSize);
        }

        if (href !== "") {

            var $modalWindow = $('.modal[data-attr-group="' + groupName + '"]');


            if ($modalWindow.find('.modal-external').find('*').length === 0) {
                $modalWindow.find('.modal-external').load("" + href + "", function () {
                    openModal(groupName, addModalFunctionName);
                    newModalMethods();
                });
            } else {

                if (single) {
                    openModal(groupName, secondFunction);
                    newModalMethods();
                } else {
                    $modalWindow.find('.modal-external').load("" + href + "", function () {
                        openModal(groupName, addModalFunctionName);
                        newModalMethods();
                    });
                }

            }


        } else {
            openModal(groupName, addModalFunctionName);
        }



    };

    openModal = function (groupName, addModalFunctionName) {

        var $modalCt = $('.modal-custom[data-attr-group="' + groupName + '"]');


        if (!!addModalFunctionName) {
            var splitFunction = addModalFunctionName.split(',');
        }

        if (!!addModalFunctionName) {
            $modalCt.on('show.bs.modal', function (e) {


                if (!!splitFunction[2]) {
                    window[splitFunction[0]](splitFunction[1], splitFunction[2]);
                }else if(!!splitFunction[1]) {
                    window[splitFunction[0]](splitFunction[1]);
                }else{
                    window[splitFunction[0]]();
                }

                $(e.currentTarget).unbind('show.bs.modal');
            });
        }

        $("body").css("padding-right", "0");
        $modalCt.modal("show");

        // $('.modal-trigger').off('click', startModalEvent);
        // $('.modal-trigger').on('click', startModalEvent);



        //confirm de x'e basında sayfa yenilenmesi gerekiyor
        $(".pageRefresh .close").click(function () {
            location.reload();
        });


        setTimeout(function () {
            $("body").addClass("modal-open");
            initModal();
        }, 500);

    };

    initModal = function () {


        var $body = $('body');

        $body.off('click.startModalEvent');
        $body.on('click.startModalEvent', '.modal-trigger', startModalEvent);


        //$('.modal-trigger').off('click', startModalEvent);
        //$('.modal-trigger').on('click', startModalEvent);
    };



    var functions = {
        initModal: initModal
    };

    return functions;

};
var tippy = require("tippy.js");

tooltips = function () {

    if (!detectmob()) {
        $(".tooltips").hover(function (elem) {


            tippy(this, {
                content: document.querySelector('#' + $(this).attr("data-html-id")),
                arrow: true,
                animation: 'scale',
                touchHold: false,
            })

        })

    }else{
        $(".tooltips").hover(function (elem) {


            tippy(this, {
                content: document.querySelector('#' + $(this).attr("data-html-id")),
                arrow: true,
                trigger: 'click',
                animation: 'scale',
                touchHold: false,
            })

        })

    }



};
tooltips();
menuOpen = function () {
    if (detectmob()) {


        $(".menu").click(function () {
            document.getElementById("mySidenav").style.width = "300px";
            $("body,html").css("overflow", "hidden");
        });

        $(".close").click(function () {
            $("body,html").css("overflow", "visible");
            document.getElementById("mySidenav").style.width = "0px";
        })

        $(".userName").click(function () {
            $(this).parent().find("ul").toggleClass("open")
        });

        $(".submenu").click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).parent().find("ul").slideToggle();
            $(this).find("i").toggleClass("rotate")
        });

    }

    $("body").click(function () {
        $(".selectPopover").removeClass("open");
    });


};

menuOpen();

priceLangSelect = function (btn) {
    $(".selectPopover").removeClass("open");
    setTimeout(function () {
        $(btn).parents(".selectbox").find(".selectPopover").toggleClass("open");
    }, 100);
};


corporateMenu = function () {
    $(".corporateCt .active").click(function () {
        $(this).parents(".leftMenu").find("ul li").toggleClass("show");
    });
};

corporateMenu();
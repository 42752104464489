(function (global){
global.$ = global.jQuery = require('../js/main/jquery.js');

require('../js/plugins/jquery-ui.min');


require('../js/plugins/daterangepicker.min');
require('../js/plugins/jquery.mask');

require('../js/plugins/modal');


var modal = require('../js/main/_modal');
modalWindow = new modal();
modalWindow.initModal();

require('../js/main/detectMobile');

require('../js/main/tooltip');

require('../js/main/footer');

require('../js/main/menu');

require('../js/main/loading');

require('../js/plugins/jquery.validate');

require('../js/main/form-validation-options');


}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})


detectmob = function () {
    var isMobileDevice = !!(navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i));

    var isMobileResulation = $(document).width() <= 748;

    if (isMobileResulation || isMobileDevice) {
        return true;
    } else {
        return false;
    }

};



